import React from "react"
import {Link} from 'gatsby'

function FeatureCards({ title, para, link }) {
  return (
    <div className="feature-overview-cards" id="overview-cards">
      {
        link ? (<><Link to={link}><h4>{title}</h4></Link></>) : (<><h4>{title}</h4></>)
      }
      <p>{para}</p>
    </div>
  )
}

export default FeatureCards
