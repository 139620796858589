import React, {useEffect, useState} from 'react'
import {Link} from 'gatsby'
import { ReactComponent as TriangleRight } from '../../../static/app-icons/layout-icons/triangle-right-arrow.svg';
import { ReactComponent as TriangleLeft } from '../../../static/app-icons/layout-icons/triangle-left-arrow.svg';

const pages = [
    "/features/safety-training/",
    "/features/certificate-tracking/",
    "/features/policies/",
    "/features/standard-operating-procedures/",
    "/features/competencies/",
    "/features/contractor-orientations/",
    "/features/worksite-screening/",
    "/features/inspections/",
    "/features/incident-reporting/",
    "/features/behavior-observations/",
    "/features/action-items/",
    "/features/safety-meetings/",
    "/features/forms/",
    "/features/bulletins/",
    "/features/suggestions/",
    "/features/polls/",
    "/features/surveys/",
    "/features/worker-management/",
    "/features/whistleblower/",
    "/features/rewards/",
    "/features/anniversaries/",
    "/features/kudos/",
    "/features/scratch-cards/",
    "/features/joke-of-the-week/",
    "/features/safety-data-sheets/",
    "/features/legislation/",
    "/features/audits/",
  ];

function FeaturePagination() {

  const [prev, setPrev] = useState(0);
  const [next, setNext] = useState(0);


  useEffect(() => {


        const position = typeof window === "undefined"
        ? 0
        : pages.indexOf(window.location.pathname);

      // Avoid using an invalid index position
      const prevPath = position <= 0 ? pages[pages.length - 1] : pages[position - 1];
      const nextPath = position >= pages.length - 1 ? pages[0] : pages[position + 1];

      setPrev(prevPath)
      setNext(nextPath)

      
  });

// console.log(position)
// console.log(window.location.pathname)

// console.log(nextPath)
// console.log(prevPath)


    return (
        <div className="feature_pagination">

         <div className="pag_left">
        <Link to={prev}><TriangleLeft className="t_hover triangle_left"/> prev</Link>
        </div>

        <div className="back_to_features">
        <Link to="/features">features</Link>
        </div>

        <div className="pag_right">
        <Link  to={next}>next <TriangleRight className="t_hover triangle_right"/></Link>
        </div>
   
        </div>
    )
}

export default FeaturePagination
